export const theme = {
  colors: {
    black: '#000000',
    blue: {
      50: '#ECEDF1', // should be replaced with clay-20
      800: '#818996', // should be replaced with clay-350
      900: '#141A24', // should be replaced with clay-900
    },
    clay: {
      10: '#F7F7F7',
      20: '#ECEDF1',
      40: '#DADDE4',
      100: '#CAD3E1',
      300: '#939BA8',
      350: '#818996',
      380: '#707884',
      400: '#5F6773',
      500: '#4F5662',
      600: '#3F4652',
      700: '#303742',
      800: '#232934',
      900: '#141A24',
      1000: '#1A1C20',
    },
    // deprecated family, should be removed after redesign is finished
    corduroy: {
      50: '#FAFAFC',
      100: '#EEEFF3',
      150: '#EBECF0',
      200: '#E2E4EB',
      300: '#C8CAD0',
      500: '#8E9197',
      700: '#5C5F64',
      800: '#393C42',
    },
    gold: {
      600: '#BC9D00',
    },
    // deprecated family, should be removed after redesign is finished
    gray: {
      100: '#F5F6F7',
    },
    green: {
      100: '#F5FFC7',
      500: '#95AE44',
      600: '#2DA859',
      800: '#1BC085',
    },
    indigo: {
      100: '#D4D9FF',
      400: '#8C94DB',
    },
    lime: {
      200: '#CFDEA1',
    },
    pink: {
      500: '#FF5473',
    },
    primary: {
      30: '#F6F1FF',
      40: '#F5F0FF',
      50: '#F1EAFF',
      100: '#EBE1FF',
      150: '#DDD3F0',
      200: '#E1D3FF',
      300: '#CDB5FF',
      800: '#8356E2',
      900: '#7A4AE1',
      1000: '#6733D5', // not from designs
    },
    red: {
      800: '#FD5A5A',
      900: '#E21818',
    },
    steel: {
      50: '#F4F5F9',
      800: '#3F315E',
      900: '#2A213E',
      1000: '#171123',
    },
    transparent: 'transparent',
    turquoise: {
      40: '#EFFEFF',
      50: '#E2FDFF',
      100: '#D0F9F5',
      200: '#C3EFEB',
      800: '#22C6B6',
      900: '#02BEAC',
      1000: '#03AD9D',
    },
    tusk: {
      100: '#E2F2B4',
      200: '#CFDEA1',
      300: '#D0F26F',
    },
    white: '#FFFFFF',
    yellow: {
      500: '#FF9E0D',
    },
  },
  extend: {
    boxShadow: {
      boxy: 'inset 0 -4px rgba(0, 0, 0, 0.04)',
    },
    dropShadow: {
      mdAll: '0 0 8px rgba(0, 0, 0, 0.1)',
      smAll: '0 0 4px rgba(0, 0, 0, 0.06)',
    },
    gridTemplateRows: {
      layout: 'var(--header-height) 1fr',
    },
    width: { inherit: 'inherit' },
  },
  fontFamily: {
    body: ['DM Sans'],
    display: ['DM Sans'],
    fira: ['Fira Code'],
    klapt: ['Klapt'],
  },
  screens: {
    '2xl': '1536px',
    '3xl': '1920px',
    lg: '1024px',
    md: '768px',
    sm: '640px',
    xl: '1280px',
    xs: '480px',
  },
} as const;
