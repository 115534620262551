import { useState } from 'react';

import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

import type { IconName } from 'shared/ui/Icon';

import { getCategoryLabel } from 'pages/GalleryHome/helpers/getCategoryLabel';
import { useMinWidthMediaQuery } from 'shared/hooks/useMediaQuery';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Popover } from 'shared/ui/Popover';
import { Select } from 'shared/ui/Select';

type FilterItem = {
  icon: IconName;
  value: string;
};

export const filterItems: FilterItem[] = [
  { icon: 'textSelect', value: 'text-generation' },
  { icon: 'image', value: 'image-generation' },
  { icon: 'box', value: 'video-generation' }, // No
  { icon: 'box', value: 'text-to-speech' },
  { icon: 'question', value: 'question-answering' },
  { icon: 'language', value: 'translation' },
  { icon: 'galleryHorizontalEnd', value: 'image-segmentation' },
  { icon: 'box', value: 'automatic-speech-recognition' },
  { icon: 'box', value: 'text-classification' },
  { icon: 'box', value: 'speech-to-text' },
  { icon: 'box', value: 'audio-classification' },
  { icon: 'box', value: 'sentence-similarity' },
  { icon: 'logs', value: 'summarization' },
  { icon: 'box', value: 'named-entity-recognition' }, // No
  { icon: 'fullscreen', value: 'object-detection' },
  { icon: 'box', value: 'ocr' },
  { icon: 'grid2x2Check', value: 'image-classification' },
  { icon: 'box', value: 'metabolic-modeling' }, // No
  { icon: 'box', value: 'system-biology' }, // No
  { icon: 'box', value: 'spatial-transcriptomics' },
  { icon: 'box', value: 'structual-bioinformatics' }, // No
  { icon: 'box', value: 'depth-estimation' },
  { icon: 'box', value: 'feature-extractor' },
  { icon: 'fileBox', value: 'centralized-models' }, // No
];

type ExtractValues<T> = T extends { value: infer V } ? V : never;
type filterItemValues = ExtractValues<(typeof filterItems)[number]>;

export type TypeFilter = filterItemValues;

export type SortFilter =
  | 'downloads-higest'
  | 'likes-higest'
  | 'name-higest'
  | 'name-lowest'
  | 'price-highest'
  | 'price-lowest'
  | 'ranking-higest'
  | 'ranking-lowest';

export type FilterState = {
  globalSearch?: string;
  sort?: SortFilter | null;
  type?: null | string[];
};

type Props = {
  filters: FilterState;
  onFilterChange: (filters: FilterState) => void;
};

export const GalleryFilters = ({ filters, onFilterChange }: Props) => {
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [isMultiselectOpen, setIsMultiselectOpen] = useState(false);
  const sm = useMinWidthMediaQuery('sm');
  const shouldShowNumber = (filters.type?.length || 0) >= 1;

  const onSortChange = (value: SortFilter) => {
    onFilterChange({ sort: value });
  };

  return (
    <div className="mt-5 flex h-fit items-center justify-between px-4">
      <div className={twMerge('flex items-center gap-4', !sm && 'flex-col items-start gap-2')}>
        <span className="text-2xl font-bold text-blue-900">Gallery</span>
        <div className="flex h-11 items-center gap-1 rounded-xl bg-blue-50 p-1">
          <Popover onOpenChange={setIsMultiselectOpen} open={isMultiselectOpen}>
            <Popover.Trigger asChild>
              <div
                className={twMerge(
                  'flex h-9 w-fit cursor-pointer items-center rounded-lg bg-gray-100 pl-4 pr-3 text-sm font-semibold text-clay-600 inner-border-0 hover:bg-clay-20',
                  (isMultiselectOpen || shouldShowNumber) && 'bg-primary-800 text-white hover:bg-primary-800',
                )}
                onClick={() => setSelectedValues(filters.type || [])}
              >
                {shouldShowNumber ? `${(filters?.type || []).length} Selected` : 'All'}
                <motion.div
                  animate={{ rotate: isMultiselectOpen ? -180 : 0 }}
                  initial={{ rotate: 0 }}
                  transition={{ duration: 0.25 }}
                >
                  <Icon
                    className={twMerge(
                      'size-6 p-0 text-corduroy-700',
                      (isMultiselectOpen || shouldShowNumber) && 'text-white',
                    )}
                    name="arrowDownSm"
                  />
                </motion.div>
              </div>
            </Popover.Trigger>
            <Popover.Content
              align="start"
              alignOffset={0}
              className="z-10 w-fit overflow-y-auto rounded-lg px-2 pb-2 pt-1 shadow-sm"
              sideOffset={10}
            >
              <div className="border-b border-blue-50 px-2 pb-2.5 pt-2 text-sm font-medium text-clay-300">
                Select Multiple Model Types
              </div>
              <div className="mt-2 grid h-80 grid-cols-2 gap-1.5 overflow-x-auto lg:h-auto lg:grid-cols-3 xl:grid-cols-4">
                {filterItems.map((item) => (
                  <div
                    className={twMerge(
                      'group flex h-fit w-48 cursor-pointer flex-row items-center justify-start gap-1 rounded-md border border-blue-50 p-2.5 text-xs font-medium text-clay-700 transition-colors aria-selected:bg-clay-20 hover:bg-clay-20 xs:w-64 xs:gap-2 xs:p-3 xs:text-sm',
                      selectedValues?.includes(item.value) && 'bg-primary-200 hover:bg-primary-200',
                    )}
                    key={item.value}
                    onClick={() => {
                      if (selectedValues.includes(item.value)) {
                        setSelectedValues(selectedValues.filter((value) => value !== item.value));
                      } else {
                        setSelectedValues([...selectedValues, item.value]);
                      }
                    }}
                  >
                    <div className="flex size-5 items-center justify-center rounded-lg bg-clay-10 p-0.5 xs:size-8 xs:p-2">
                      <Icon
                        className="size-3 p-0 text-clay-350 group-hover:text-clay-700 xs:size-3.5"
                        name={item.icon}
                      />
                    </div>
                    {getCategoryLabel(item.value)}
                  </div>
                ))}
              </div>

              <div className="mt-2 flex h-8 w-full justify-between">
                <Button
                  className="pl-4 outline-0"
                  color="secondary"
                  onClick={() => {
                    onFilterChange({ ...filters, type: [] });
                    setIsMultiselectOpen(false);
                  }}
                  size="extra-small"
                  variant="filled-light"
                >
                  <Icon className="size-3 text-blue-800" name="refresh" />
                  <div className="mt-px text-xs font-medium text-blue-800">Reset all</div>
                </Button>

                <Button
                  className="flex h-8 items-center justify-center gap-2 rounded-lg bg-primary-800 px-3 outline-0"
                  onClick={() => {
                    onFilterChange({ ...filters, type: selectedValues });
                    setIsMultiselectOpen(false);
                  }}
                  size="extra-small"
                  variant="filled-light"
                >
                  <Icon className="size-3 text-white" name="check" />
                  <div className="text-xs font-medium text-white">Apply</div>
                </Button>
              </div>
            </Popover.Content>
          </Popover>

          <div className="flex h-9 cursor-not-allowed items-center gap-2 rounded-lg bg-gray-100 px-4 opacity-60">
            <Icon className="-mt-px size-4 text-primary-800" name="logo" />
            <span className="text-sm font-medium text-clay-600">Nesa&apos;s Pick</span>
          </div>

          <div className="flex h-9 cursor-not-allowed items-center gap-2 rounded-lg bg-gray-100 px-4 opacity-60">
            <Icon className="-mt-px size-4 text-green-500" name="star" />
            <span className="text-sm font-medium text-clay-600">Featured</span>
          </div>
        </div>
      </div>

      {sm && (
        <div className="right-4 box-border flex h-11 items-center gap-1 rounded-xl bg-blue-50 p-1">
          <Select
            className="h-9 w-fit rounded-lg bg-gray-100 p-3 text-sm inner-border-0"
            iconName="arrowDownUp"
            onValueChange={onSortChange}
            placeholder="Sort by"
            placeholderClassName="font-medium text-clay-600"
            value={filters.sort ? filters.sort : undefined}
          >
            <Select.Content className="z-10 rounded-lg p-1 shadow-sm">
              <div className="mb-2 w-48 border-b border-blue-50 px-2 pb-2.5 pt-2 text-sm font-medium text-blue-800">
                Sort by
              </div>
              <Select.Item
                className="text-sm text-clay-700 hover:bg-blue-50"
                indicatorClassName="size-3 text-clay-700"
                value="newest"
              >
                Newest
              </Select.Item>
              <Select.Item
                className="border-b border-blue-50 text-sm text-clay-700 hover:bg-blue-50"
                indicatorClassName="size-3 text-clay-700"
                value="oldest"
              >
                Oldest
              </Select.Item>
              <Select.Item
                className="text-sm text-clay-700 hover:bg-blue-50"
                indicatorClassName="size-3 text-clay-700"
                value="name-lowest"
              >
                Name Ascending
              </Select.Item>
              <Select.Item
                className="border-b border-blue-50 text-sm text-clay-700 hover:bg-blue-50"
                indicatorClassName="size-3 text-clay-700"
                value="name-highest"
              >
                Name Decending
              </Select.Item>
              <Select.Item
                className="text-sm text-clay-700 hover:bg-blue-50"
                indicatorClassName="size-3 text-clay-700"
                value="ranking-highest"
              >
                Most Rated
              </Select.Item>
              <Select.Item
                className="text-sm text-clay-700 hover:bg-blue-50"
                indicatorClassName="size-3 text-clay-700"
                value="likes-highest"
              >
                Most Likes
              </Select.Item>
              <Select.Item
                className="border-b border-blue-50 text-sm text-clay-700 hover:bg-blue-50"
                indicatorClassName="size-3 text-clay-700"
                value="downloads-highest"
              >
                Most Downloaded
              </Select.Item>
              <Select.Item
                className="text-sm text-clay-700 hover:bg-blue-50"
                indicatorClassName="size-3 text-clay-700"
                value="price-highest"
              >
                Price Highest
              </Select.Item>
              <Select.Item
                className="text-sm text-clay-700 hover:bg-blue-50"
                indicatorClassName="size-3 text-clay-700"
                value="price-lowest"
              >
                Price Lowest
              </Select.Item>
            </Select.Content>
          </Select>

          <Select
            className="h-9 w-fit rounded-lg bg-gray-100 p-3 text-sm inner-border-0"
            disabled={true}
            iconName="filter"
            onValueChange={onSortChange}
            placeholder="Filter"
            placeholderClassName="font-medium text-clay-600"
          >
            <Select.Content className="z-10 rounded-lg px-2 pb-2 pt-1 shadow-sm">
              <div className="mb-2 border-b border-blue-50 px-2 pb-2.5 pt-2 text-sm font-medium text-blue-800">
                Filter
              </div>

              <div className="flex w-full flex-col">
                <div className="flex justify-between pb-1.5">
                  <div className="text-xs font-medium text-clay-500">Data Range</div>
                  <div className="text-xs font-medium text-primary-800">Reset</div>
                </div>

                <div className="flex flex-col gap-1">
                  <div className="h-3.5 text-[0.6875rem] font-medium text-clay-300">From</div>
                  <div className="h-9 rounded-lg border border-corduroy-150 px-2 py-2.5">hi</div>
                </div>
              </div>

              {/* <hr className="border-t border-blue-50" /> */}

              <div className="mt-2 flex h-8 w-full justify-between">
                <div className="flex items-center gap-2 rounded-lg bg-clay-10 pl-2.5 pr-3">
                  <Icon className="size-3 text-blue-800" name="refresh" />
                  <div className="mt-px text-xs font-medium text-blue-800">Reset all</div>
                </div>

                <div className="flex items-center gap-2 rounded-lg bg-primary-800 pl-2.5 pr-3">
                  <Icon className="size-3 text-tusk-100" name="check" />
                  <div className="mt-px text-xs font-semibold text-white">Apply</div>
                </div>
              </div>
            </Select.Content>
          </Select>
        </div>
      )}
    </div>
  );
};
