import {
  type ComponentProps,
  type ElementType,
  type ForwardedRef,
  type PropsWithChildren,
  forwardRef,
} from 'react';

import { twMerge } from 'tailwind-merge';

import type { ClassName, PolymorphicProps } from 'shared/types';

import { Spinner } from '../Spinner';

export type ButtonVariant = 'filled' | 'filled-light';
export type ButtonColor = 'primary' | 'secondary';

export type Props = {
  asIcon?: boolean;
  color?: ButtonColor;
  isLoading?: boolean;
  size?: 'extra-small' | 'medium' | 'small';
  variant?: ButtonVariant;
} & ClassName;

const Button = <E extends ElementType = 'button'>(
  props: PropsWithChildren<PolymorphicProps<E, Props>>,
  ref: ForwardedRef<E>,
) => {
  const {
    as: Component = 'button',
    asIcon,
    children,
    className,
    color = 'primary',
    disabled,
    isLoading,
    size = 'small',
    variant = 'filled',
    ...restProps
  } = props;

  const isLocked = disabled || isLoading;

  const isSecondaryLightFilled = color === 'secondary' && variant === 'filled-light';
  const isPrimaryLightFilled = color === 'primary' && variant === 'filled-light';
  const isPrimaryFilled = color === 'primary' && variant === 'filled';
  const isSecondaryFilled = color === 'secondary' && variant === 'filled';

  const iconButtonSize = {
    'extra-small': 'size-8',
    medium: 'size-12',
    small: 'size-10',
  }[size];
  const buttonSize = {
    'extra-small': 'h-8 px-3 py-1 text-xs',
    medium: 'h-12 px-5 py-4 text-sm font-light',
    small: 'h-10 px-4 py-2 text-xs lg:text-sm',
  }[size];

  return (
    <Component
      className={twMerge(
        'relative inline-flex cursor-pointer items-center justify-center gap-1 rounded-lg text-center font-medium transition-all',
        isLocked && 'pointer-events-none cursor-not-allowed',
        isLocked && 'opacity-60',

        asIcon ? iconButtonSize : buttonSize,

        isSecondaryLightFilled && 'bg-clay-10 text-clay-350 hover:brightness-95',
        isPrimaryFilled && 'bg-primary-800 text-white hover:bg-primary-1000',
        isPrimaryLightFilled && 'border-primary-100 bg-primary-100 text-primary-1000 hover:brightness-95',
        isSecondaryFilled && 'bg-clay-20 text-clay-900 hover:bg-clay-40',

        className,
      )}
      disabled={disabled || isLoading}
      ref={ref as ComponentProps<typeof Component>['ref']}
      {...restProps}
    >
      {isLoading ? <Spinner className="size-4" /> : children}
    </Component>
  );
};

const ForwardButton = forwardRef(Button) as typeof Button;

export { ForwardButton as Button };
