import { useSearchParams } from 'react-router-dom';

import { useModelListQuery } from 'shared/api/models/useGetModelListQuery';
import { cleanObject } from 'shared/helpers/cleanObject';
import { useStateX } from 'shared/hooks/useStateX';
import { AnimateRoute } from 'shared/ui/AnimateRoute';

import type { FilterState } from './ui/GalleryFilters/GalleryFilters';

import { getFiltersParams } from './helpers/getFiltersParams';
import { Gallery } from './ui/Gallery';
import { GalleryFilters } from './ui/GalleryFilters';

const buildParams = (data: Record<string, string | string[]>) => {
  const params = new URLSearchParams();

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((value) => params.append(key, value.toString()));
    } else {
      params.append(key, value.toString());
    }
  });

  return params;
};

export const GalleryHome = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useStateX<FilterState>({
    globalSearch: searchParams.get('globalSearch') || undefined,
    sort: searchParams.get('sort') as FilterState['sort'],
    type: searchParams.getAll('type') || [],
  });
  const sortFilters = getFiltersParams(filters);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isPending } = useModelListQuery({
    direction: sortFilters.direction,
    sort: sortFilters.sort || undefined,
    type: filters.type || undefined,
  });

  const modelsFull = data?.pages.flat();

  const handleFilterChange = (update: FilterState) => {
    const newFilters = { ...filters, ...update };
    const cleanFilters = cleanObject(newFilters, { removeNull: true });

    setSearchParams(buildParams(cleanFilters));
    setFilters(newFilters);
  };

  return (
    <AnimateRoute className="flex flex-1 grow flex-col overflow-y-hidden">
      <GalleryFilters filters={filters} onFilterChange={handleFilterChange} />

      <Gallery
        fetchNextPage={fetchNextPage}
        filters={filters}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        isPending={isPending}
        layout
        modelList={modelsFull}
        onFilterChange={handleFilterChange}
      />
    </AnimateRoute>
  );
};
