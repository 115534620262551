import type { PropsWithChildren, ReactNode } from 'react';
import { useState } from 'react';

import * as SelectPrimitive from '@radix-ui/react-select';
import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

import type { Name } from '../Icon/Icon';

import { Icon } from '../Icon';
import { Label } from '../Label';

type Size = {
  color?: 'asInput' | 'gray';
  size?: 'medium' | 'small';
};

type Props = {
  classNameLabel?: string;
  iconClassName?: string;
  iconName?: string;
  label?: ReactNode;
  placeholder?: string;
  placeholderClassName?: string;
  wrapperClassName?: string;
} & ClassName &
  SelectPrimitive.SelectProps &
  Size;

export const Select = ({
  children,
  className,
  classNameLabel,
  color = 'asInput',
  defaultOpen,
  disabled,
  iconClassName,
  iconName,
  label,
  onOpenChange: onOpenChangeProp,
  open: openProp,
  placeholder,
  placeholderClassName,
  size = 'small',
  value,
  wrapperClassName,
  ...props
}: Props) => {
  const [open, setOpen] = useState(defaultOpen);

  const isControl = typeof openProp === 'boolean' && typeof onOpenChangeProp === 'function';

  const isOpen = isControl ? openProp : open;
  const onOpenChange = isControl ? onOpenChangeProp : setOpen;

  return (
    <SelectPrimitive.Root
      {...props}
      disabled={disabled}
      key={value}
      onOpenChange={onOpenChange}
      open={isOpen}
      value={value}
    >
      <div className={twMerge('flex flex-col', wrapperClassName)}>
        {label && <Label className={classNameLabel}>{label}</Label>}
        <SelectPrimitive.Trigger
          className={twMerge(
            'flex w-full items-center rounded-lg bg-clay-10 px-5 py-2 pr-2 leading-none outline-none transition-all hover:bg-clay-20 focus:outline-none xs:py-3',
            size === 'small' && 'h-10 text-xs lg:text-sm',
            size === 'medium' && 'h-12 text-xs',
            color === 'gray' && 'bg-clay-20 text-clay-350 hover:brightness-95',
            className,
            disabled && 'pointer-events-none cursor-not-allowed bg-clay-40',
            isOpen && 'shadow-sm',
          )}
          onClick={() => onOpenChange(true)}
          onPointerDown={(e) => e.preventDefault()}
        >
          {!!iconName && (
            <Icon className={twMerge('mr-2 size-3.5 text-blue-800', iconClassName)} name={iconName as Name} />
          )}
          {value ? (
            <SelectPrimitive.Value />
          ) : (
            <span className={twMerge('font-light text-clay-300', placeholderClassName)}>{placeholder}</span>
          )}

          <SelectPrimitive.Icon className="ml-auto flex items-center justify-center">
            <motion.div
              animate={{ rotate: isOpen ? -180 : 0 }}
              initial={{ rotate: 0 }}
              transition={{ duration: 0.25 }}
            >
              <Icon
                className={twMerge('size-6 p-0', disabled ? 'text-clay-300' : 'text-corduroy-700')}
                name="arrowDownSm"
              />
            </motion.div>
          </SelectPrimitive.Icon>
        </SelectPrimitive.Trigger>
      </div>

      {children}
    </SelectPrimitive.Root>
  );
};

const SelectContent = ({ children, className, ...restProps }: SelectPrimitive.SelectContentProps) => {
  return (
    <SelectPrimitive.Portal>
      <SelectPrimitive.Content
        position="popper"
        sideOffset={4}
        {...restProps}
        className={twMerge(
          'max-h-[var(--radix-select-content-available-height)] min-w-[var(--radix-select-trigger-width)] max-w-[var(--radix-select-content-available-width)]',
          'overflow-hidden rounded-lg bg-white p-1 inner-border inner-border-clay-20',
          className,
        )}
      >
        <SelectPrimitive.ScrollUpButton>
          <Icon className="mx-auto size-6 rotate-180 text-clay-600" name="arrowDownSm" />
        </SelectPrimitive.ScrollUpButton>
        <SelectPrimitive.Viewport>{children}</SelectPrimitive.Viewport>
        <SelectPrimitive.ScrollDownButton>
          <Icon className="mx-auto size-6 text-clay-600" name="arrowDownSm" />
        </SelectPrimitive.ScrollDownButton>
      </SelectPrimitive.Content>
    </SelectPrimitive.Portal>
  );
};

type SelectItemProps = PropsWithChildren<
  {
    hasIndicator?: boolean;
    indicatorClassName?: string;
    startSlot?: ReactNode;
  } & SelectPrimitive.SelectItemProps
>;

const SelectItem = ({
  children,
  className,
  hasIndicator = true,
  indicatorClassName,
  startSlot: StartSlot,
  ...props
}: SelectItemProps) => {
  return (
    <SelectPrimitive.Item
      {...props}
      className={twMerge(
        'flex w-full cursor-pointer items-center justify-between rounded-lg px-3 py-2 text-sm text-clay-900 transition-colors hover:bg-clay-20 focus:outline-none lg:text-sm',
        className,
      )}
    >
      {StartSlot}
      <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
      {hasIndicator && (
        <SelectPrimitive.ItemIndicator>
          <Icon className={twMerge('size-3 text-clay-700', indicatorClassName)} name="check" />
        </SelectPrimitive.ItemIndicator>
      )}
    </SelectPrimitive.Item>
  );
};

Select.Item = SelectItem;
Select.Content = SelectContent;
