import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';

import { useUser } from 'app/stores/user';
import { UploadModelModal } from 'features/UploadModelModal';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';

export const MyModel = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const [isUploadOpen, setUploadOpen] = useState(false);

  if (!user) return null;

  return (
    <div className="flex h-9 items-center">
      <div
        className={twMerge(
          'flex h-full w-fit cursor-pointer items-center justify-between rounded-lg bg-clay-20 p-0.5 pl-2.5 transition-colors hover:bg-primary-40 2xl:min-w-[19rem]',
        )}
        onClick={() => navigate('/models')}
      >
        <div className="flex size-fit items-center gap-1">
          <div className="text-[0.8125rem] font-semibold">My AI Node</div>
          <div className="size-1 self-start rounded bg-green-600"> </div>
        </div>

        <div className="ml-1.5">
          <span className="text-[0.625rem] font-semibold text-clay-300">ID:&nbsp;</span>
          <span className="text-[0.625rem] font-normal text-clay-300">32560317486</span>
        </div>

        <div className="ml-3 flex gap-px">
          <div className="flex h-8 items-center rounded-l-md bg-white p-2">
            <Icon className="size-3 text-clay-400" name="box" />
            <span className="ml-1 text-xs font-semibold">234</span>
          </div>

          <div className="flex h-8 items-center rounded-r-md bg-white p-2">
            <Icon className="size-3 text-primary-800" name="crown" />
            <span className="ml-1 text-xs font-semibold">98</span>
          </div>
        </div>
      </div>

      <Button
        className="ml-3 h-9 pl-3 pr-4"
        onClick={() => {
          setUploadOpen(true);
        }}
      >
        <Icon className="size-4 text-white" name="packagePlus" />
        Upload Model
      </Button>

      <UploadModelModal isOpen={isUploadOpen} onOpenChange={setUploadOpen} />
    </div>
  );
};
