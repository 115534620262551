const CATEGORY_LABEL_MAP = {
  'automatic-speech-recognition': {
    description: 'Transcribing spoken language into text.',
    title: 'Speech\nRecognition\nASR',
  },
  'depth-estimation': {
    description: 'Predicting depth to build 3D scenes from images.',
    title: 'Depth\nEstimation',
  },
  'feature-extractor': {
    description: 'Extracting high-level features from diverse data types.',
    title: 'Feature\nExtractor',
  },
  'image-classification': {
    description: 'Categorizing and identifying objects or scenes in images.',
    title: 'Image\nClassification',
  },
  'image-generation': {
    description: 'Generating images from textual descriptions to visualize concepts and ideas.',
    title: 'Image\nGeneration',
  },
  'image-segmentation': {
    description: 'Segmenting images into meaningful components.',
    title: 'Image\nSegmentation',
  },
  'instance-segmentation': { description: '', title: 'Instance\nSegmentation' },
  'object-detection': {
    description: 'Detecting and localizing objects in images.',
    title: 'Object\nDetection',
  },
  ocr: {
    description: 'Extracting text from images and documents.',
    title: 'Character\nRecognition\nOCR',
  },
  'panoptic-segmentation': { description: '', title: 'Panoptic\nSegmentation' },
  'question-answering': {
    description: 'Answering queries with contextual understanding.',
    title: 'Question\nAnswering',
  },
  summarization: {
    description: 'Creating concise summaries of longer texts.',
    title: 'Content\nSummarization',
  },

  'text-classification': {
    description: 'Categorizing text for tasks like sentiment or topic classification.',
    title: 'Text\nClassification',
  },
  'text-generation': {
    description: 'Generating coherent and relevant text across domains.',
    title: 'Text\nGeneration',
  },
  'token-classification': {
    description: 'Labeling words and tokens for tasks like named entity recognition.',
    title: 'Token\nClassification',
  },
  translation: {
    description: 'Translating text across languages while maintaining meaning.',
    title: 'Language\nTranslation',
  },
};

export const getCategoryLabel = (category: string) => {
  if (!category) {
    return '';
  }

  return (
    CATEGORY_LABEL_MAP[category as keyof typeof CATEGORY_LABEL_MAP]?.title ||
    category
      .split('-')
      .reduce((acc, str, i) => `${acc}${i > 0 ? ' ' : ''}${str.charAt(0).toUpperCase()}${str.slice(1)}\n`, '')
  );
};

export const getCategoryDescription = (category: string) => {
  return CATEGORY_LABEL_MAP[category as keyof typeof CATEGORY_LABEL_MAP]?.description || '';
};
