import { useState } from 'react';

import { twJoin, twMerge } from 'tailwind-merge';

import type { Model } from 'shared/api/models/types';
import type { ClassName } from 'shared/types';

import logoSrc from 'app/assets/images/logo.svg';
import { getCategoryLabel } from 'pages/GalleryHome/helpers/getCategoryLabel';
import { getSplittedModelName } from 'shared/helpers/getSplittedModelName';
import { Tooltip } from 'shared/ui/Tooltip';

import { ContentLoader } from '../ContentLoader';
import { StretchedSkeleton } from '../StretchedSkeleton';
import { GalleryCardBottomInfo } from './ui/GalleryCardBottomInfo';

type Tag = 'featured' | 'nesas-pick';

type Props = {
  isDescriptionPreview?: boolean;
  isLoading?: boolean;
  model?: Model;
  onClick?: () => void;
  tags?: Tag[];
} & ClassName;

export const GalleryCard = ({ className, isLoading, model, onClick, tags }: Props) => {
  const [statusImage, setStatusImage] = useState('loading');
  const { name } = getSplittedModelName(model?.name || '');

  return (
    <div
      className={twMerge(
        className,
        'rounded-lg border border-transparent bg-white p-3 transition-all duration-300 hover:border-[#ADB0B6] hover:drop-shadow-mdAll',
      )}
    >
      <div className={twJoin('flex flex-col', !!onClick && 'cursor-pointer')} onClick={onClick}>
        <div className="flex items-start justify-between">
          <div className="relative size-11 overflow-hidden rounded-lg xs:size-10 xs:min-w-10 lg:size-11 lg:min-w-11">
            {!isLoading && (
              <img
                className="size-full overflow-hidden object-cover object-center"
                onError={() => setStatusImage('error')}
                onLoad={() => setStatusImage('success')}
                src={model?.image}
              />
            )}
            {statusImage === 'loading' && (
              <ContentLoader className="absolute left-0 top-0" height="100%" width="100%">
                <rect height="100%" rx="5" ry="5" width="100%" x="0" y="0" />
              </ContentLoader>
            )}
          </div>
          <div className="-mt-1">
            <Tooltip content="per token" side="top">
              <div className="relative flex h-6 min-w-16 max-w-fit gap-1 !pl-1.5 text-sm font-semibold leading-6 text-black">
                <StretchedSkeleton enable={isLoading} rx={12} ry={12} />

                {!isLoading && (
                  <>
                    <div className="flex size-6 items-center justify-center rounded-full p-1">
                      <img className="size-full" src={logoSrc} />
                    </div>
                    <span className="mt-px font-semibold text-blue-900">0.001</span>
                  </>
                )}
              </div>
            </Tooltip>
          </div>
        </div>

        <div className="relative mt-3 flex h-6 items-center gap-1">
          {!isLoading ? (
            <>
              {model?.type && (
                <div className="rounded border border-blue-50 p-1.5 text-xs/none font-semibold uppercase text-clay-300">
                  {getCategoryLabel(model.type)}
                </div>
              )}
              {tags?.includes('nesas-pick') && (
                <div className="rounded border border-primary-150 bg-primary-30 p-1.5 text-xs/none font-semibold uppercase text-primary-800">
                  Nesa&apos;s Pick
                </div>
              )}

              {tags?.includes('featured') && (
                <div className="rounded border border-tusk-200 bg-green-100/40 p-1.5 text-xs/none font-semibold uppercase text-green-500">
                  Featured
                </div>
              )}
            </>
          ) : (
            <StretchedSkeleton enable={isLoading} />
          )}
        </div>

        <div className="mt-1.5 flex flex-1 flex-col justify-between">
          <div className="flex">
            <div className="relative flex-1 grow">
              <div className="relative mb-1 line-clamp-1 h-6 text-ellipsis break-all text-lg/6 font-semibold capitalize">
                <StretchedSkeleton enable={isLoading} />

                {!isLoading && name}
              </div>

              <div
                className={twMerge(
                  'relative line-clamp-2 h-10 text-ellipsis whitespace-pre-line text-sm font-light text-clay-380',
                )}
              >
                <StretchedSkeleton enable={isLoading} />

                {!isLoading && model?.description}
              </div>
            </div>
          </div>

          <div className="mt-3 h-px w-full bg-blue-50" />
        </div>
      </div>
      <GalleryCardBottomInfo
        className="mt-3 xs:@md:hidden"
        isLoading={isLoading}
        latency={model?.latency}
        likes={model?.likes}
        reviewCount={model?.reviewsCount}
      />
    </div>
  );
};
