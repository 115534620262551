import type { InputHTMLAttributes, ReactNode } from 'react';
import { forwardRef } from 'react';

import { twMerge } from 'tailwind-merge';

import { Label } from '../Label';

interface Props extends InputHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
  error?: string;
  innerClassName?: string;
  label?: ReactNode;
  labelClassName?: string;
  rows?: number;
}

export const TextArea = forwardRef<HTMLTextAreaElement, Props>(
  ({ className, disabled, error, innerClassName, label, labelClassName, rows = 4, ...props }: Props, ref) => {
    return (
      <div className={twMerge('relative flex flex-col gap-0 pb-4', className)}>
        {label && <Label className={labelClassName}>{label}</Label>}
        <textarea
          className={twMerge(
            'rounded-lg border border-clay-20 bg-white p-2 text-base font-normal outline-none transition-colors',
            'placeholder:font-light hover:border-clay-100 focus:border-clay-100 2xl:text-sm',
            'disabled:pointer-events-none disabled:cursor-not-allowed disabled:bg-clay-20',
            innerClassName,
          )}
          disabled={disabled}
          ref={ref}
          rows={rows}
          {...props}
        ></textarea>
        {error && (
          <div className="absolute bottom-3 translate-y-full text-[10px] leading-none text-pink-500">
            {error}
          </div>
        )}
      </div>
    );
  },
);
