import type { HTMLAttributeAnchorTarget } from 'react';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AxiosError } from 'axios';
import { AnimatePresence } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

import type { IconName } from 'shared/ui/Icon';

import { GalleryLogo } from 'app/ui/GalleryLogo';
import { useUploadModelMutation } from 'shared/api/models/useUploadModelMutation';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Modal } from 'shared/ui/Modal';

import uploadSrc from './assets/upload.svg';
import { Description } from './ui/Description';
import { HardwareSpecifications } from './ui/HardwareSpecifications';
import { Huggingface } from './ui/Huggingface';
import { ModelImage } from './ui/ModelImage';
import { Visibility } from './ui/Visibility';

const guides: {
  icon: IconName;
  iconClassName?: string;
  label: string;
  target?: HTMLAttributeAnchorTarget;
  url: string;
}[] = [
  { icon: 'bolt', iconClassName: 'stroke-clay-400 text-transparent', label: 'Getting Started', url: '' },
  { icon: 'book', iconClassName: '', label: 'Documentation', target: '_blank', url: 'https://docs.nesa.ai' },
  { icon: 'huggingface', iconClassName: 'text-transparent', label: 'Huggingface', url: '' },
];

const steps = [
  { component: ModelImage, name: 'model-image', nextStep: 'description' },
  { component: Description, name: 'description', nextStep: 'hardware-specs', prevStep: 'model-image' },
  {
    component: HardwareSpecifications,
    name: 'hardware-specs',
    nextStep: 'huggingface-repo',
    prevStep: 'description',
  },
  { component: Huggingface, name: 'huggingface-repo', nextStep: 'visibility', prevStep: 'hardware-specs' },
  { component: Visibility, name: 'visibility', prevStep: 'huggingface-repo' },
];

type Props = {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
};

export type UploadModelValues = {
  brand: string;
  description: string;
  deviceType: string;
  generatedImage?: string;
  hardware: string;
  hardwareDescription: string;
  imageDescription: string;
  license: string;
  modelAccessType?: 'private' | 'public';
  modelName: string;
  modelType: string;
  repositoryName: string;
};

export const UploadModelModal = ({ isOpen, onOpenChange }: Props) => {
  const [isUploaded, setIsUploaded] = useState(false);
  const [step, setStep] = useState('model-image');

  const navigate = useNavigate();
  const { isPending: isUploading, mutateAsync } = useUploadModelMutation();

  const methods = useForm<UploadModelValues>({
    defaultValues: {
      brand: '',
      description: '',
      deviceType: 'gpu-worker',
      generatedImage: '',
      hardware: '',
      hardwareDescription: '',
      license: '',
      modelAccessType: undefined,
      modelName: '',
      modelType: '',
      repositoryName: '',
    },
    mode: 'onChange',
  });

  const {
    formState: { errors, isValid },
    handleSubmit,
  } = methods;

  console.log('isValid', isValid, errors);

  const handleUploadModel = async () => {
    if (!isValid) {
      toast.error('Not all required fields are filled');
      return;
    }

    await handleSubmit(async (values) => {
      try {
        const { model_id } = await mutateAsync({
          description: values.description,
          model_name: values.modelName,
          model_type: values.modelType,
          private: values.modelAccessType === 'private',
        });

        if (!model_id) {
          toast.error('No model available');
          return;
        }

        setIsUploaded(true);
      } catch (e) {
        console.log('ERROR', e);
        if (e instanceof AxiosError) {
          const axiosError = e.response?.data?.message;
          toast.error(axiosError || 'Something went wrong');
        } else {
          toast.error('Something went wrong');
        }
      }
    })();
  };

  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <FormProvider {...methods}>
        <Modal.Content className="w-full md:min-w-fit" innerClassName=" bg-clay-10 p-4 md:p-8">
          <div className="flex flex-col gap-8 md:flex-row">
            <div className="w-full md:w-64">
              <GalleryLogo className="size-12 md:size-14" isBlack />
              <div className="mt-4 md:mt-8">
                <div className="text-2xl font-semibold text-clay-900 md:text-3xl">Create a Model</div>
                <div className="mt-2 text-sm font-light text-clay-500">
                  Set up your model on Nesa, connect it with huggingface, get rewards and build our growing AI
                  community.
                </div>
              </div>
              <div className="mt-6 md:mt-12">
                <div className="mb-3 text-xs font-normal text-clay-500">Guides & Tips</div>

                <div className="flex flex-col gap-3">
                  {guides.map(({ icon, iconClassName, label, target, url }) => {
                    return (
                      <Link
                        className="group flex items-center gap-1.5"
                        key={label}
                        onClick={() => {
                          if (!url) toast.info('Coming soon');
                        }}
                        target={target}
                        to={url}
                      >
                        <Icon className={twMerge('size-3.5 text-clay-400', iconClassName)} name={icon} />
                        <span className="text-sm font-normal text-clay-800 transition-colors group-hover:text-primary-800">
                          {label}
                        </span>
                      </Link>
                    );
                  })}
                </div>
              </div>

              {isUploaded && (
                <Button
                  className="mt-14 rounded-lg px-3"
                  isLoading={isUploading}
                  onClick={() => setIsUploaded(false)}
                >
                  <Icon className="mr-1 size-4" name="packagePlus" />
                  Upload new model
                </Button>
              )}
            </div>

            <AnimatePresence>
              <div className="mb-6 flex h-auto w-full flex-col justify-center overflow-hidden rounded-xl bg-white shadow-md md:w-[44rem]">
                {isUploaded ? (
                  <>
                    <img className="size-24 self-center" src={uploadSrc} />

                    <div className="mt-3 w-64 self-center text-center text-2xl/7 font-semibold text-clay-900">
                      Model uploaded successfully!
                    </div>

                    <div className="mt-2 w-64 self-center text-center text-sm font-normal text-clay-500">
                      Your model can be accessed now in Nesa Playground. Enjoy!
                    </div>

                    <div
                      className="mt-4 flex h-9 cursor-pointer items-center self-center rounded-lg bg-clay-20 px-3 text-sm text-clay-900 hover:bg-clay-40"
                      onClick={() => {
                        navigate('/');
                        onOpenChange(false);
                      }}
                    >
                      Back to Dashboard
                      <Icon className="ml-2 size-4 p-0" name="arrowUpRight" />
                    </div>
                  </>
                ) : (
                  steps.map(({ component: Component, name, nextStep, prevStep }) => (
                    <Component
                      isExpanded={step === name}
                      key={name}
                      onNextClick={nextStep ? () => setStep(nextStep) : () => {}}
                      onPrevClick={prevStep ? () => setStep(prevStep) : () => {}}
                      onTitleClick={() => setStep(name)}
                    />
                  ))
                )}
              </div>
            </AnimatePresence>
          </div>

          {!isUploaded && (
            <div className="flex items-center justify-between">
              <Button
                className="rounded-lg bg-clay-20 px-3 text-clay-900"
                color="secondary"
                onClick={() => onOpenChange(false)}
                variant="filled-light"
              >
                Cancel
              </Button>

              <Button disabled={!isValid} isLoading={isUploading} onClick={handleUploadModel}>
                Upload Model
              </Button>
            </div>
          )}
        </Modal.Content>
      </FormProvider>
    </Modal>
  );
};
