import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AnimatePresence } from 'framer-motion';
// eslint-disable-next-line import/default
import mapboxgl from 'mapbox-gl';

import { GalleryHome as Home } from 'pages/GalleryHome';
import { LeaderBoard } from 'pages/LeaderBoard2';
import { NotFound } from 'pages/NotFound';
import { Profile } from 'pages/Profile';
import { Wallet } from 'pages/Wallet';
// import { RestrictedAccess } from 'shared/provider/RestrictedAccess';
import { Tooltip } from 'shared/ui/Tooltip';

import { Loading } from './ui/Loading';

import './App.css';
import 'jsoneditor/dist/jsoneditor.min.css';
import 'jsoneditor/dist/jsoneditor.min.js';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-toastify/dist/ReactToastify.css'; // eslint-disable-line import/no-webpack-loader-syntax
import { BuildOnNesa } from 'pages/BuildOnNesa';
import { Ecosystem } from 'pages/Ecosystem';
import { EmailVerification } from 'pages/EmailVerification';
import { Faucet } from 'pages/Faucet';
// import { Faucet } from 'pages/Faucet';
import { Component } from 'pages/Map';
import { ModelPage } from 'pages/ModelPage';
import { Models } from 'pages/Models';
import { NodePage } from 'pages/NodePage';
import { Nodes } from 'pages/Nodes';
import { PasswordRecovery } from 'pages/PasswordRecovery';
import { Preview } from 'pages/Preview';
import { QueryHistory } from 'pages/QueryHistory';
import { QueryPage } from 'pages/QueryPage';
import { ResetPassword } from 'pages/ResetPassword';
import { Settings } from 'pages/Settings';
import { SignUp } from 'pages/SignUp';
import { VerifyEmail } from 'pages/VerifyEmail';
import { Whitelist } from 'pages/Whitelist';

import { AuthGuard } from './providers/AuthGuard';
import { UserContextProvider } from './stores/user';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(mapboxgl as unknown as any).accessToken =
  'pk.eyJ1IjoibmVzMTIzNCIsImEiOiJjbHYxM2dlb2IwMnZtMmpuc3dtYnJmazk1In0.Y-elBIdPB-l3OVkM-QD_DQ';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

export const App = () => {
  // const [isLoading, setIsLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(import.meta.env.MODE !== 'development');

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
      clearTimeout(timeout);
    }, 1500);
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <UserContextProvider>
        <Tooltip.Provider>
          <AnimatePresence>{isLoading && <Loading />}</AnimatePresence>
          <AnimatePresence>
            <Routes>
              <Route element={<Preview />} path="/preview" />
              <Route element={<SignUp />} path="/signup" />
              <Route element={<VerifyEmail />} path="/signup/verify" />

              <Route element={<AuthGuard />}>
                <Route element={<Home />} path="/" />
                <Route element={<ModelPage />} path="/models/:id/details" />
                <Route element={<QueryPage />} path="/models/:id/:section?" />
                <Route element={<NodePage />} path="/nodes/:id" />
                <Route element={<Component />} path="/map" />
                <Route element={<Wallet />} path="/wallet" />
                <Route element={<Profile />} path="/account/:id" />
                <Route element={<Settings />} path="/settings" />
                <Route element={<QueryHistory />} path="/query-history" />
                <Route element={<LeaderBoard />} path="/leader-board" />
                <Route element={<BuildOnNesa />} path="/build-on-nesa" />
                <Route element={<Faucet />} path="/faucet" />
                <Route element={<Ecosystem />} path="/ecosystem" />
                <Route element={<Nodes />} path="/nodes" />
                <Route element={<Models />} path="/models" />
              </Route>

              <Route element={<PasswordRecovery />} path="/recovery/password" />

              <Route element={<EmailVerification />} path="/users/:userId/verification/email/:token" />
              <Route element={<ResetPassword />} path="/users/:userId/reset/password/:token" />

              <Route element={<Whitelist />} path="/whitelist" />
              <Route element={<NotFound />} path="*" />
            </Routes>
          </AnimatePresence>
        </Tooltip.Provider>
      </UserContextProvider>
      <ToastContainer />
    </QueryClientProvider>
  );
};
